import {
    Box,
    Center,
    Collapse,
    Flex,
    Heading,
    Stack,
    Text,
    VStack,
    useDisclosure,
} from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { motion } from "framer-motion";

import FadeIn from "../components/Animation/FadeIn";
import IFBanner from "../components/IF_Template/IF_banner";
import IFPricing from "../components/IF_Template/IF_Pricing";
import IFSubMenu from "../components/IF_SubMenu";
import IFSwiper from "../components/IF_Swiper";
import IFHeading from "../components/IF_Heading";

import { getPostural } from "../api/postural";
import Loading from "../components/Layout/Loading";

function CollapseEx({ children, title }) {
    const { isOpen, onToggle } = useDisclosure();

    return (
        <Stack
            onClick={onToggle}
            transition="all .3s"
            bgColor={isOpen ? "rgba(234, 42, 94, .7)" : ""}
            rounded="xl"
            width={{ base: "40%", lg: "30%" }}
        >
            <Center>
                <FadeIn>
                    <Center bgColor="pink" rounded="xl" height="12vh">
                        <Heading textAlign="center" fontSize="2xl">
                            {title}
                        </Heading>
                    </Center>
                </FadeIn>
            </Center>
            <Collapse in={isOpen} animateOpacity>
                <Box pb={4} px={4}>
                    {children}
                </Box>
            </Collapse>
        </Stack>
    );
}

export default function Postural() {
    const [load, setLoad] = useState(true);
    const [data, setDatas] = useState([]);
    useEffect(() => {
        getPostural().then((res) => {
            setDatas(res);
            setLoad(false);
        });
    }, []);

    if (load) {
        return <Loading />
    }

    return (
        <>
            <IFBanner>
                <motion.div
                    animate={{
                        opacity: [0, 0.5, 1],
                        x: ["100%", "-20%", "0%"],
                    }}
                    transition={{ duration: 0.5, delay: 1 }}
                >
                    <Flex
                        flexDir={{ base: "column-reverse", xl: "row" }}
                        gap={{ base: 2, xl: 4 }}
                    >
                        <Box>
                            <IFSubMenu
                                text1={data.datas[0].value}
                                text2={data.datas[1].value}
                            />
                            <Text fontSize="xl">
                                {data.datas[2].value}
                            </Text>
                        </Box>
                        <video
                            style={{
                                alignSelf: "center",
                                width: "fit-content",
                                height: "200px",
                            }}
                            controls={true}
                            autoPlay={true}
                            muted={true}
                            src={process.env.REACT_APP_IMAGE_URL + data.datas[3].value}
                        ></video>
                    </Flex>
                </motion.div>
            </IFBanner>

            <Box role="group" pb={20}>
                <IFHeading>MASALAH PADA TULANG BELAKANG</IFHeading>
                <Flex
                    flexWrap="wrap"
                    mx="auto"
                    justifyContent="center"
                    mt={8}
                    width={{ base: "95%", xl: "80%" }}
                    gap={{ base: 4, xl: 8 }}
                >
                    {JSON.parse(data.datas[4].value).map((item, index) => (
                        <CollapseEx
                            title={item.title.toUpperCase()}
                            key={index}
                        >
                            <Text whiteSpace="pre-line" textAlign="center" fontSize="xl">
                                {item.description}
                            </Text>
                        </CollapseEx>
                    ))}
                </Flex>
            </Box>

            <Box role="group">
                <IFHeading>KONSULTASI & ASSESSMENT</IFHeading>

                <Center pb={12} mb={4}>
                    <Stack
                        w={{ base: "90%", lg: "70%" }}
                        alignItems="center"
                        textAlign="center"
                    >
                        <VStack gap={2}>
                            <FadeIn>
                                <Text whiteSpace="pre-line" fontSize="xl">
                                    {data.datas[5].value}
                                </Text>
                            </FadeIn>
                        </VStack>
                        <FadeIn>
                            <Center
                                mt={2}
                                mx="auto"
                                w={{ base: "100%", xl: "50vw" }}
                            >
                                <img alt="" src={process.env.REACT_APP_IMAGE_URL + data.datas[6].value} />
                            </Center>
                        </FadeIn>
                    </Stack>
                </Center>
            </Box>

            <Box role="group">
                <IFHeading>POSTURAL PROGRAM STUDIO & HOME</IFHeading>
                <IFPricing
                    datas={JSON.parse(data.datas[8].value)}
                    gradientColor="pink"
                >
                    <FadeIn>
                        <Text textAlign="center" whiteSpace="pre-line" fontSize="xl">
                            {data.datas[7].value}
                        </Text>
                    </FadeIn>
                </IFPricing>
            </Box>

            <IFSwiper data={data.album} />
        </>
    );
}
