import { Avatar, HStack, Heading, Stack, VStack } from "@chakra-ui/react";
import { Outlet } from "react-router-dom";
import Header from "../components/Layout/Header";
import Footer from "../components/Layout/Footer";
import { useEffect, useState } from "react";
import { getContact } from "../api/contact";
import Loading from "../components/Layout/Loading";

const textForWA = "Hallo, Saya ingin bertanya mengenai I Fit Sport Indonesia";

export default function Main() {
    const [showNumber, setShowNumber] = useState(false);
    const [load, setLoad] = useState(true);
    const [data, setDatas] = useState([]);
    useEffect(() => {
        getContact().then((res) => {
            setDatas(res);
            setLoad(false);
        });
    }, []);

    if (load) {
        return <Loading />
    }

    return (
        <Stack
            gap="0px"
            w="100%"
            bgColor="gray.900"
            color="white"
            pos="relative"
        >
            <Header />

            <Outlet />

            <Footer social_media={JSON.parse(data[1].value)} />
            <a
                href={`https://wa.me/6282125476557?text=${textForWA
                    .split(" ")
                    .join("%20")}`}
                target="blank"
            >
                <VStack
                    _hover={{
                        opacity: 1,
                    }}
                    onMouseEnter={() => setShowNumber(true)}
                    onMouseLeave={() => setShowNumber(false)}
                    zIndex={10}
                    opacity="0.7"
                    pos="fixed"
                    bottom={8}
                    right={8}
                    transition="opacity .3s"
                >
                    <HStack
                        justifyContent="space-between"
                        px={1}
                        py={1}
                        w="100%"
                        rounded="full"
                        bgColor="pink"
                    >
                        <Heading ps={2} fontSize={{ base: "md", lg: "lg" }}>
                            {!showNumber ? "Contact Us" : "0821-2547-6557"}
                        </Heading>
                        <Avatar w={8} h={8} src="/icon/whatsapp.png" />
                    </HStack>
                </VStack>
            </a>
        </Stack>
    );
}
