import axios from "axios";

export async function getCertification() {
    return await axios
        .get(`${process.env.REACT_APP_API_URL}getCertification.php`)
        .then((res) => res.data)
        .catch((err) => {
            console.log(err);
            return {
                datas: [],
                album: []
            };
        });
}
