import {
    Box,
    Center,
    Circle,
    Flex,
    Grid,
    GridItem,
    Heading,
    Icon,
    Stack,
    Text,
    VStack,
    Wrap,
    WrapItem,
    useBreakpointValue,
} from "@chakra-ui/react";
import { HiOutlineLocationMarker } from "react-icons/hi";
import { useEffect, useState } from "react";
import { motion } from "framer-motion";

import FadeIn from "../components/Animation/FadeIn";
import IFSubMenu from "../components/IF_SubMenu";

import { getContact } from "../api/contact";
import Loading from "../components/Layout/Loading";

function LocalSocialMedia({ contact, index }) {
    const delay_animate = useBreakpointValue([0, index / 2]);

    return (
        <FadeIn delay={delay_animate} duration={1}>
            <Center h="100%">
                <a href={contact.link} target="blank">
                    <Center
                        role="group"
                        gap={[0, 4]}
                        flexDir="column"
                    >
                        <Center w={{ base: "10vw", lg: "5vw", xl: "10vh" }}>
                            <img alt="" src={process.env.REACT_APP_IMAGE_URL + contact.icon} />
                        </Center>
                        <Heading fontSize="3xl">{contact.text}</Heading>
                    </Center>
                </a>
            </Center>
        </FadeIn>
    );
}

export default function Contact() {
    const [load, setLoad] = useState(true);
    const [data, setDatas] = useState([]);
    useEffect(() => {
        getContact().then((res) => {
            setDatas(res);
            setLoad(false);
        });
    }, []);

    if (load) {
        return <Loading />
    }
    return (
        <>

            <Center
                gap={8}
                py={{ base: "10vh", xl: "0" }}
                h={{ base: "auto", xl: "100vh" }}
                bg="url(/images/bg.jpg) no-repeat bottom"
                bgSize="cover"
                overflow="hidden"
                mt="0px !important"
                flexDir={{ base: "column", lg: "row" }}
            >
                <motion.div
                    initial={{
                        scale: 2,
                        opacity: 0,
                        filter: "blur(5px) grayScale(1)",
                    }}
                    animate={{
                        opacity: 1,
                        scale: 1,
                        filter: "blur(0px) grayScale(0)",
                    }}
                    transition={{ duration: 1, delay: 0.2 }}
                >
                    <Center pos="relative">
                        <Circle
                            zIndex={1}
                            w={{ base: "50vw", md: "30vw", xl: "20vw" }}
                            p="5vh"
                            overflow="hidden"
                            bgColor="white"
                        >
                            <img src="/logo-crop.png" alt="" />
                        </Circle>
                    </Center>
                </motion.div>
                <motion.div
                    animate={{
                        opacity: [0, 0.5, 1],
                        x: ["100%", "-20%", "0%"],
                    }}
                    transition={{ duration: 0.5, delay: 1 }}
                >
                    <Stack w={{ base: "80vw", xl: "60vw" }} gap={8}>
                        <Box>
                            <IFSubMenu text1="" text2="BUSINESS HOURS" />
                            <Text whiteSpace="pre-line" fontSize="xl">
                                {data[0].value}
                            </Text>
                        </Box>

                        <Box>
                            <IFSubMenu text1="" text2="ADDRESS" />
                            <Stack gap={1}>
                                {JSON.parse(data[2].value).map((a, index) => (
                                    <Flex gap="0.5rem" key={index} h="full">
                                        <Icon
                                            mt="0.3rem"
                                            fontSize="xl"
                                            color="pink"
                                            as={HiOutlineLocationMarker}
                                        />
                                        <Text fontSize="xl">{a.address}</Text>
                                    </Flex>
                                ))}
                            </Stack>
                        </Box>
                    </Stack>
                </motion.div>
            </Center>
            <Center zIndex={0} p={10} py={{ xl: 20 }}>
                <Wrap w={{ base: "90vw", md: "70vw" }} spacing={12} justify="center">
                    {JSON.parse(data[1].value).map((contact, index) => (
                        <WrapItem key={index}>
                            <LocalSocialMedia
                                key={contact.link}
                                contact={contact}
                                index={index}
                            />
                        </WrapItem>
                    ))}
                </Wrap>
            </Center>
            <VStack gap={{ base: 24, lg: 20 }} mb={{ base: "1rem", lg: "2rem" }}>
                {JSON.parse(data[2].value).map((a, index) => {
                    return <Grid ml={{ lg: index % 2 === 0 ? "7rem" : "-7rem" }}
                        gap="1rem"
                        sx={{
                            direction: { base: "rtl", lg: index % 2 === 0 ? "ltr" : "rtl" },
                        }} templateColumns={{ lg: "repeat(3, 1fr)" }}
                        key={index} w={{ base: "95vw", lg: "70vw" }} h={{ base: "80vh", lg: "50vh" }} pos="relative" >
                        <Box pos="absolute" w="105%" top="50%" left="50%"
                            transform="translate(-50%,-50%)" bgGradient={{ base: "linear(to-b, pink,transparent)", lg: "linear(to-t, pink, transparent,transparent)" }} h={{ base: "calc(100% + 2rem)", lg: "calc(100% + 4rem)" }}></Box>
                        <motion.div
                            style={{
                                "height": "100%"
                            }}
                            viewport={{
                                once: true,
                                amount: 0.5,
                            }}
                            initial={{
                                opacity: 0,
                                x: index % 2 === 0 ? "50%" : "-50%",
                            }}
                            whileInView={{
                                opacity: 1,
                                x: 0,
                            }}
                            transition={{ duration: 0.5 }}
                        >
                            <a href={process.env.REACT_APP_IMAGE_URL + a.image}>
                                <Box h={{ base: "40vh", lg: "50vh" }}>
                                    <img style={{ objectFit: "cover", objectPosition: "bottom", width: "100%", height: "100%" }} src={process.env.REACT_APP_IMAGE_URL + a.image} alt="" />
                                </Box>
                            </a>
                        </motion.div>
                        <GridItem h={{ base: "40vh", lg: "auto" }} colSpan={{ lg: 2 }}>
                            <motion.div
                                style={{
                                    height: "100%"
                                }}
                                viewport={{
                                    once: true,
                                    amount: 0.5,
                                }}
                                initial={{
                                    opacity: 0,
                                    x: index % 2 === 0 ? "-50%" : "50%",
                                }}
                                whileInView={{
                                    opacity: 1,
                                    x: 0,
                                }}
                                transition={{ duration: 0.5, delay: 0.5 }}
                            >
                                <iframe
                                    style={{
                                        width: "100%",
                                        height: "100%",
                                    }}
                                    title={a.address}
                                    src={a.maps}
                                    allowFullScreen={true}
                                    referrerPolicy="no-referrer-when-downgrade"
                                />
                            </motion.div>
                        </GridItem>
                    </Grid>
                })}
            </VStack >
        </>
    );
}
