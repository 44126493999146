import {
    Box,
    Heading,
    Center,
    useBreakpointValue,
    Wrap,
    WrapItem,
    VStack,
} from "@chakra-ui/react";
import FadeIn from "../Animation/FadeIn";

export default function IF_Pricing({ datas, children }) {
    const breakpoints = useBreakpointValue({
        base: 0,
        md: 0.5,
    });

    return (
        <Center px={{ base: 4, xl: 0 }} w="100%">
            <VStack w={{ base: "90%", xl: "70%" }} flexDir="column">
                {children}
                <Wrap
                    justify='center'
                    spacing={{ base: 4, xl: 8 }}
                    py={[4, 10]}
                    w={"full"}
                >
                    {datas.map((data, index) => (
                        <WrapItem key={index}>
                            <FadeIn delay={index * breakpoints}>
                                <Box
                                    w="100%"
                                    h="100%"
                                    pos="absolute"
                                    bgColor="whiteAlpha.800"
                                    rounded="xl"
                                ></Box>
                                <Center
                                    _hover={{
                                        transform: "translate(-5px, -5px)",
                                    }}
                                    transition="all 0.2s"
                                    h="100%"
                                    pos="relative"
                                    bgColor="pink"
                                    borderRadius="xl"
                                >
                                    <Box textAlign="center" my={10} mx={12}>
                                        <Heading
                                            key={index}
                                            fontSize="2xl"
                                            display="inline"
                                        >
                                            {data.title}
                                        </Heading>
                                    </Box>
                                </Center>
                            </FadeIn>
                        </WrapItem>
                    ))}
                </Wrap>
            </VStack>
        </Center>
    );
}
