import axios from "axios";

export async function getGallery() {
    return await axios
        .get(`${process.env.REACT_APP_API_URL}getGallery.php`)
        .then((res) => res.data)
        .catch((err) => {
            console.log(err);
            return {
                studio: [],
                gymManagement: [],
                certification: [],
            };
        });
}
