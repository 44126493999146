import {
    AspectRatio,
    Box,
    Center,
    Grid,
    GridItem,
    Heading,
    Stack,
    VStack,
} from "@chakra-ui/react";
import IFBanner from "../components/IF_Template/IF_banner";
import { motion } from "framer-motion";
import { getTeam } from "../api/team";
import { useEffect, useState } from "react";
import Loading from "../components/Layout/Loading";

export default function Team() {
    const [load, setLoad] = useState(true);
    const [data, setDatas] = useState([]);
    useEffect(() => {
        getTeam().then((res) => {
            setDatas(res);
            setLoad(false);
        });
    }, []);

    if (load) {
        return <Loading />
    }

    return (
        <>
            <IFBanner h={{ base: "auto", md: "55vh", lg: "70vh", xl: "90vh" }}>
                <motion.div
                    animate={{
                        opacity: [0, 0.5, 1],
                        x: ["100%", "-20%", "0%"],
                    }}
                    transition={{ duration: 0.5, delay: 1 }}
                >
                    <Stack
                        gap={0}
                        mb={4}
                        style={{
                            paddingLeft: ".5rem",
                            borderLeftWidth: ".2rem",
                        }}
                    >
                        <Heading fontSize="2xl" whiteSpace={{ lg: "nowrap" }}>
                            {data.datas[0].value}
                        </Heading>
                        <Heading m="0px !important" color="pink" fontSize="2xl">
                            {data.datas[1].value}
                        </Heading>
                    </Stack>
                </motion.div>
            </IFBanner>

            <Center>
                <Grid
                    w="80%"
                    templateColumns={{
                        base: "repeat(2,1fr)",
                        md: "repeat(3,1fr)",
                        xl: "repeat(4,1fr)",
                    }}
                    gap={10}
                    py={10}
                >
                    {data.album.map((t, index) => (
                        <GridItem key={index}>
                            <motion.div
                                whileInView={{
                                    opacity: 1,
                                    scale: 1,
                                    y: 0,
                                }}
                                initial={{
                                    scale: 2,
                                    opacity: 0,
                                    y: "50%",
                                }}
                                transition={{
                                    duration: 0.5,
                                }}
                            >
                                <AspectRatio
                                    role="group"
                                    pos="relative"
                                    overflow="hidden"
                                    ratio={2 / 3}
                                >
                                    <>
                                        <VStack
                                            _groupHover={{
                                                filter: "blur(1px) grayscale(100%) brightness(50%)",
                                                transform:
                                                    "perspective(500px) rotateX(70deg)",
                                            }}
                                            roundedTop="xl"
                                            bg={`white url(${process.env.REACT_APP_IMAGE_ALBUM_URL + t.filename
                                                })  no-repeat  center`}
                                            bgSize="cover"
                                            transform="perspective(500px)"
                                            transition="all .5s ease-in-out"
                                            transformOrigin="bottom"
                                        />
                                        <Box
                                            transition="all .5s ease-in-out"
                                            opacity="0"
                                            transform="translateY(50%)"
                                            _groupHover={{
                                                opacity: "1",
                                                transform: "translateY(0%)",
                                            }}
                                            h="100%"
                                            w="100%"
                                            pos="absolute"
                                            bg={`url(${process.env.REACT_APP_IMAGE_ALBUM_URL + t.filename
                                                }) center`}
                                            bgSize="cover"
                                        />
                                    </>
                                </AspectRatio>
                            </motion.div>
                        </GridItem>
                    ))}
                </Grid>
            </Center>
        </>
    );
}
