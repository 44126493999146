import { BrowserRouter, Route, Routes } from "react-router-dom";
import Home from "./pages";
import Certification from "./pages/certification";
import Contact from "./pages/contact";
import Gallery from "./pages/gallery";
import Gymmanagement from "./pages/gymmanagement";
import Trainer from "./pages/personaltrainer";
import Postural from "./pages/postural";
import Team from "./pages/team";
import Main from "./templates/main";

function App() {
    return (
        <BrowserRouter>
            <Routes>
                <Route path="/" element={<Main />}>
                    <Route index element={<Home />} />
                    <Route path="postural" element={<Postural />} />
                    <Route path="personaltrainer" element={<Trainer />} />
                    <Route path="gymmanagement" element={<Gymmanagement />} />
                    <Route path="certification" element={<Certification />} />
                    <Route path="team" element={<Team />} />
                    <Route path="contact" element={<Contact />} />
                    <Route path="gallery" element={<Gallery />} />
                </Route>
            </Routes>
        </BrowserRouter>
    );
}

export default App;
