import {
    Box,
    Heading,
    Stack,
    Text,
    VStack,
    Center,
    Circle,
    HStack,
    Icon,
    AspectRatio,
} from "@chakra-ui/react";
import { CiLocationArrow1 } from "react-icons/ci";
import { useEffect, useState } from "react";

import { motion } from "framer-motion";

import FadeIn from "../components/Animation/FadeIn";
import IFHeading from "../components/IF_Heading";
import IFSwiper from "../components/IF_Swiper";
import IF_SubMenu from "../components/IF_SubMenu";

import { getHome } from "../api/home";
import Loading from "../components/Layout/Loading";

export default function Home() {
    const [load, setLoad] = useState(true);
    const [data, setDatas] = useState([]);
    useEffect(() => {
        getHome().then((res) => {
            setDatas(res);
            setLoad(false);
        });
    }, []);

    if (load) {
        return <Loading />
    }
    return (
        <Stack mt="0px !important">
            <Center
                w="100%"
                py={{ base: "12vh", xl: "0" }}
                h={{ base: "auto", xl: "100vh" }}
                overflow="hidden"
                pos="relative"
            >
                <motion.div
                    animate={{
                        opacity: [0, 1],
                    }}
                    transition={{ duration: 1, ease: "easeInOut" }}
                    style={{
                        height: "100%",
                        width: "100%",
                        position: "absolute",
                        background: "url('/images/bg.jpg') no-repeat bottom",
                        bgSize: "cover",
                    }}
                ></motion.div>
                <Center
                    textAlign="center"
                    gap={{ base: 4, md: 8, lg: 4 }}
                    w={{ base: "90%", xl: "80%" }}
                    flexDir="column"
                >
                    <motion.div
                        animate={{
                            scale: [1, 1.1],
                        }}
                        transition={{
                            repeat: Infinity,
                            repeatType: "reverse",
                            duration: 1,
                        }}
                    >
                        <Circle
                            overflow="hidden"
                            p={6}
                            bgColor="whiteAlpha.900"
                            w={{ base: "45vw", md: "28vw", lg: "12vw" }}
                        >
                            <img src="/logo-crop.png" alt="" />
                        </Circle>
                    </motion.div>

                    <VStack zIndex={1}>
                        <motion.div
                            animate={{
                                scale: [3, 1],
                                opacity: [0, 1],
                            }}
                            transition={{
                                duration: 0.5,
                            }}
                        >
                            <Heading fontSize="5xl" color="pink">
                                {data.datas[0].value}
                            </Heading>
                        </motion.div>
                        <HStack>
                            <motion.div
                                animate={{
                                    y: ["-100%", "0%"],
                                    opacity: [0, 1],
                                }}
                                transition={{
                                    duration: 0.3,
                                }}
                            >
                                <Heading
                                    textAlign="center"
                                    whiteSpace="nowrap"
                                    fontSize={{ base: "xl", md: "3xl" }}
                                >
                                    {data.datas[1].value}
                                </Heading>
                            </motion.div>
                        </HStack>

                        <Stack pt={8}>
                            {[0, 1, 2].map((i) => (
                                <motion.div
                                    key={i}
                                    transition={{
                                        duration: 1,
                                        repeat: Infinity,
                                        repeatType: "loop",
                                        repeatDelay: 1 * i,
                                        delay: 5,
                                    }}
                                    animate={{
                                        opacity: [0, 1],
                                    }}
                                >
                                    <Icon
                                        mt={-5}
                                        fontSize={{ base: "4xl", lg: "5xl" }}
                                        transform="rotate(135deg)"
                                        color="pink"
                                        as={CiLocationArrow1}
                                    />
                                </motion.div>
                            ))}
                        </Stack>
                    </VStack>
                </Center>
            </Center>

            <Box role="group">
                <IFHeading>ABOUT US</IFHeading>

                <Center>
                    <VStack py={10} w="80%" textAlign="center">
                        <Circle bgColor="pink" h={40} w={40} pos="relative">
                            <Center
                                rounded="full"
                                overflow="hidden"
                                bottom="0"
                                left="50%"
                                transform="translateX(-50%)"
                                pos="absolute"
                                w="100%"
                            >
                                <motion.div
                                    initial={{ opacity: 0 }}
                                    whileInView={{
                                        opacity: 1,
                                        x: ["-100%", "10%", "0%"],
                                    }}
                                    transition={{
                                        ease: "easeInOut",
                                        duration: 2,
                                    }}
                                    viewport={{
                                        once: true,
                                        amount: 1,
                                    }}
                                >
                                    <img alt="" src="/images/kinan-crop.png" />
                                </motion.div>
                            </Center>
                        </Circle>
                        <motion.div
                            initial={{ opacity: 0, scale: 3 }}
                            whileInView={{
                                scale: 1,
                                opacity: 1,
                            }}
                            viewport={{
                                once: true,
                            }}
                            transition={{ duration: 0.5 }}
                        >
                            <Heading color="pink">
                                {data.datas[2].value}
                            </Heading>
                        </motion.div>
                        <VStack gap={2}>
                            <FadeIn>
                                <Text whiteSpace="pre-line" fontSize="xl" display="inline-block">
                                    {data.datas[3].value}
                                </Text>
                            </FadeIn>
                        </VStack>
                    </VStack>
                </Center>
            </Box>

            <Box role="group">
                <IFHeading>OUR SERVICES</IFHeading>
                {JSON.parse(data.datas[4].value).map((s, index) => (
                    <Center
                        key={index}
                        py={4}
                        px={{ base: 8, lg: 0 }}
                        flexDir={[
                            "column",
                            index % 2 === 0 ? "row" : "row-reverse",
                        ]}
                        w="100%"
                        gap={[0, 8]}
                    >
                        <Stack>
                            <FadeIn
                                direction={index % 2 === 0 ? "right" : "left"}
                            >
                                <Center mb={{ base: 4, md: "0" }} w="100%">
                                    <AspectRatio
                                        w={{
                                            base: "80vw",
                                            md: "40vw",
                                            lg: "30vw",
                                        }}
                                        ratio={3 / 2}
                                    >
                                        <Box
                                            _hover={{
                                                _before: {
                                                    opacity: 1,
                                                    transform:
                                                        "skewX(-45deg) translateX(100vw)",
                                                },
                                                _after: {
                                                    opacity: 1,
                                                    transform:
                                                        "skewX(-45deg) translateX(-100vw)",
                                                },
                                            }}
                                            _before={{
                                                transition: "all 1s ease-in",
                                                h: "100%",
                                                content: '""',
                                                width: "30%",
                                                position: "absolute",
                                                left: 0,
                                                top: 0,
                                                zIndex: 10,
                                                transform:
                                                    "skewX(-45deg) translateX(-300%)",
                                                bgGradient:
                                                    "linear(to-r, transparent, rgba(234, 42, 94,.7), transparent)",
                                            }}
                                            _after={{
                                                transition: "all 1s ease-in",
                                                h: "100%",
                                                content: '""',
                                                width: "30%",
                                                position: "absolute",
                                                right: 0,
                                                top: 0,
                                                zIndex: 10,
                                                transform:
                                                    "skewX(-45deg) translateX(300%)",
                                                bgGradient:
                                                    "linear(to-r, transparent, rgba(255,255,255,.7), transparent)",
                                            }}
                                            overflow="hidden"
                                            h="100%"
                                            w="100%"
                                            rounded="xl"
                                            pos="relative"
                                        >
                                            <img
                                                alt=""
                                                style={{
                                                    zIndex: 1,
                                                    objectFit: "cover",
                                                }}
                                                src={process.env.REACT_APP_IMAGE_URL + s.image}
                                                loading="lazy"
                                            />
                                        </Box>
                                    </AspectRatio>
                                </Center>
                            </FadeIn>
                        </Stack>
                        <Stack w={{ base: "100%", lg: "40vw", xl: "50vw" }}>
                            <FadeIn
                                direction={index % 2 === 0 ? "left" : "right"}
                            >
                                <IF_SubMenu
                                    textAlign={
                                        index % 2 === 0 ? "left" : "right"
                                    }
                                    text1={s.title}
                                    text2={s.subTitle}
                                />

                                <Stack
                                    textAlign={
                                        index % 2 === 0 ? "start" : "end"
                                    }
                                    gap={2}
                                >
                                    <Text whiteSpace="pre-line" fontSize="xl">
                                        {s.description}
                                    </Text>
                                </Stack>
                            </FadeIn>
                        </Stack>
                    </Center>
                ))}
            </Box>

            <IFSwiper data={data.album} />
        </Stack>
    );
}
