import { Center, Heading, SimpleGrid, Stack, Text } from "@chakra-ui/react";
import { useEffect, useState } from "react";

import FadeIn from "../components/Animation/FadeIn";
import IFSwiper from "../components/IF_Swiper";

import { getManagement } from "../api/mangement.js";
import Loading from "../components/Layout/Loading.jsx";

export default function Gymmanagement() {
    const [load, setLoad] = useState(true);
    const [data, setDatas] = useState([]);
    useEffect(() => {
        getManagement().then((res) => {
            setDatas(res);
            setLoad(false);
        });
    }, []);

    if (load) {
        return <Loading />
    }

    return (
        <>
            <Center flexDir="column" pt={{ base: "10vh", xl: "20vh" }}>
                <Stack
                    w="80vw"
                    pos="relative"
                >
                    <Heading color="pink">{data.datas[0].value}</Heading>
                    <Text whiteSpace="pre-line" fontSize="xl">
                        {data.datas[1].value}
                    </Text>
                </Stack>
            </Center>

            <FadeIn>
                <Stack mx="auto" my={10} w="80vw">
                    <Heading color="pink">OUR CLIENTS</Heading>
                    <SimpleGrid
                        columns={{
                            base: 1,
                            md: 3,
                            lg: 5,
                        }}
                        gap={4}
                    >
                        {JSON.parse(data.datas[2].value).map((d) => (
                            <Stack
                                _hover={{
                                    transform: "translateY(-5px)",
                                }}
                                transition="all .3s ease-in-out"
                                key={d.src}
                            >
                                <Center
                                    border={`3px solid ${d.background}`}
                                    h={{ base: "10vh", lg: "20vh" }}
                                    bgColor={d.background}
                                >
                                    <img
                                        style={{
                                            objectFit: "contain",
                                            width: "100%",
                                            height: "100%",
                                        }}
                                        src={process.env.REACT_APP_IMAGE_URL + d.image}
                                        alt=""
                                    />
                                </Center>
                                <Text textAlign="center">{d.title}</Text>
                            </Stack>
                        ))}
                    </SimpleGrid>
                </Stack>
            </FadeIn>

            <IFSwiper data={data.album} />
        </>
    );
}
