import {
    Heading,
    Stack,
    VStack,
    Center,
    Circle,
    Spinner,
} from "@chakra-ui/react";

import { motion } from "framer-motion";

export default function Loading() {
    return (
        <Center
            w="100%"
            py={{ base: "12vh", xl: "0" }}
            h={{ base: "auto", xl: "100vh" }}
            overflow="hidden"
            pos="relative"
        >
            <motion.div
                animate={{
                    opacity: [0, 1],
                }}
                transition={{ duration: 1, ease: "easeInOut" }}
                style={{
                    height: "100%",
                    width: "100%",
                    position: "absolute",
                    background: "url('/images/bg.jpg') no-repeat bottom",
                    bgSize: "cover",
                }}
            ></motion.div>
            <Center
                textAlign="center"
                gap={{ base: 4, md: 8, lg: 4 }}
                w={{ base: "90%", xl: "80%" }}
                flexDir="column"
            >
                <motion.div
                    animate={{
                        scale: [1, 1.1],
                    }}
                    transition={{
                        repeat: Infinity,
                        repeatType: "reverse",
                        duration: 1,
                    }}
                >
                    <Circle
                        overflow="hidden"
                        p={6}
                        bgColor="whiteAlpha.900"
                        w={{ base: "45vw", md: "28vw", lg: "12vw" }}
                    >
                        <img src="/logo-crop.png" alt="" />
                    </Circle>
                </motion.div>

                <VStack zIndex={1}>
                    <motion.div
                        animate={{
                            scale: [3, 1],
                            opacity: [0, 1],
                        }}
                        transition={{
                            duration: 0.5,
                        }}
                    >
                        <Heading fontSize="5xl" color="pink">
                            Loading
                        </Heading>
                    </motion.div>

                    <Stack pt={8}>
                        <motion.div
                            transition={{
                                duration: 1,
                                repeat: Infinity,
                                repeatType: "loop",
                                delay: 5,
                            }}
                            animate={{
                                opacity: [0.6, 1],
                            }}
                        >
                            <Spinner
                                mt={-5}
                                fontSize={{ base: "4xl", lg: "5xl" }}
                                color="pink"
                            />
                        </motion.div>
                    </Stack>
                </VStack>
            </Center>
        </Center>
    );
}
