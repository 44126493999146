import {
    AspectRatio,
    Box,
    Center,
    Collapse,
    Flex,
    Heading,
    Stack,
    Text,
    useDisclosure,
} from "@chakra-ui/react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Navigation, Pagination } from "swiper";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import { motion } from "framer-motion";
import { getCertification } from "../api/certification";
import { useEffect, useState } from "react";
import FadeIn from "../components/Animation/FadeIn";
import Loading from "../components/Layout/Loading";

function CollapseEx({ children, title }) {
    const { isOpen, onToggle } = useDisclosure();

    return (
        <Center
            onClick={onToggle}
            transition="all .3s"
            pos="relative"
            width="45%"
            role="group"
            h="fit-content"
        >
            <Box
                _groupHover={{
                    bgColor: "whiteAlpha.800",
                }}
                zIndex={0}
                rounded="xl"
                pos="absolute"
                w="full"
                h="full"
            ></Box>
            <Stack
                _groupHover={{
                    transform: "translate(-5px, -5px)",
                }}
                transition="all .3s"
                bgColor={isOpen ? "#AB244C" : ""}
                rounded="xl"
                w="full"
                zIndex={1}
                h="full"
            >
                <Center>
                    <FadeIn>
                        <Center
                            minH="100px"
                            bgColor="pink"
                            rounded="xl"
                            p={{ base: 1, md: 2 }}
                        >
                            <Heading
                                textAlign="center"
                                fontSize={{ base: "lg", md: "2xl" }}
                            >
                                {title}
                            </Heading>
                        </Center>
                    </FadeIn>
                </Center>
                <Collapse in={isOpen} animateOpacity>
                    <Box pb={4} px={4}>
                        {children}
                    </Box>
                </Collapse>
            </Stack>
        </Center>
    );
}

function LocalCarousel({ data }) {
    return (
        <Center pt={{ base: "10vh", xl: "20vh" }} w="90vw">
            <Swiper
                spaceBetween={30}
                centeredSlides={true}
                autoplay={{
                    delay: 5000,
                    disableOnInteraction: false,
                }}
                pagination={{
                    clickable: true,
                }}
                navigation={true}
                modules={[Autoplay, Pagination, Navigation]}
            >
                {data.map((i) => (
                    <SwiperSlide key={i.src}>
                        <Center marginX="auto">
                            <AspectRatio
                                w={{ base: "90vw", lg: "70vw" }}
                                ratio={3 / 1}
                            >
                                <motion.div
                                    initial={{
                                        opacity: 0,
                                    }}
                                    whileInView={{
                                        opacity: 1,
                                    }}
                                    transition={{
                                        duration: 1,
                                    }}
                                    style={{
                                        background: `url(${process.env.REACT_APP_IMAGE_ALBUM_URL +
                                            i.filename
                                            }) no-repeat center`,
                                        backgroundSize: "cover",
                                        borderRadius: "10px",
                                    }}
                                />
                            </AspectRatio>
                        </Center>
                    </SwiperSlide>
                ))}
            </Swiper>
        </Center>
    );
}

export default function Certification() {
    const [load, setLoad] = useState(true);
    const [data, setDatas] = useState([]);
    useEffect(() => {
        getCertification().then((res) => {
            setDatas(res);
            setLoad(false);
        });
    }, []);

    if (load) {
        return <Loading />
    }

    return (
        <>
            <Center flexDir="column" w="100%" mt="5vh">
                <LocalCarousel data={data.album} />

                <Center px={{ base: 4, xl: 0 }} w="100%">
                    <Flex
                        flexWrap="wrap"
                        mx="auto"
                        justifyContent="center"
                        my={8}
                        width={{ base: "100%", xl: "80%" }}
                        gap={{ base: 2, xl: 8 }}
                    >
                        {JSON.parse(data.datas[0].value).map((p, index) => (
                            <CollapseEx
                                title={p.title.toUpperCase()}
                                key={index}
                            >
                                <Text
                                    whiteSpace="pre-line"
                                    textAlign="center"
                                    fontSize={{ base: "md", md: "xl" }}
                                >
                                    {p.description}
                                </Text>
                            </CollapseEx>
                        ))}
                    </Flex>
                </Center>
            </Center>
        </>
    );
}
