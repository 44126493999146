import {
    AspectRatio,
    Box,
    Heading,
    SimpleGrid,
    Tab,
    TabList,
    TabPanel,
    TabPanels,
    Tabs,
} from "@chakra-ui/react";
import { motion } from "framer-motion";
import { getGallery } from "../api/gallery.js";
import { useEffect, useState } from "react";
import Loading from "../components/Layout/Loading.jsx";

function Foto({ data }) {
    return (
        <Box pos="relative" w="full" h="full">
            <img
                onMouseMoveCapture={(e) => {
                    e.target.style.objectFit = "contain";
                }}
                onMouseOutCapture={(e) => {
                    e.target.style.objectFit = "cover";
                }}
                style={{
                    objectFit: "cover",
                    width: "100%",
                    height: "100%",
                    zIndex: 1,
                    backgroundColor: "white",
                }}
                loading="lazy"
                width={500}
                height={500}
                alt=""
                src={process.env.REACT_APP_IMAGE_ALBUM_URL + data.filename}
            />
        </Box>
    );
}

function Film({ data }) {
    return (
        <video preload="metadata" width="320" height="240" controls>
            <source
                src={`${process.env.REACT_APP_IMAGE_ALBUM_URL + data.filename}#t=1`}
                type="video/mp4"
            />
            Your browser does not support the video tag.
        </video>
    );
}

function LocalTabPanel({ datas }) {
    return (
        <TabPanel>
            <SimpleGrid
                gap={4}
                columns={{
                    base: 3,
                    xl: 4,
                }}
            >
                {datas.map((data, i) => (
                    <motion.div
                        key={i}
                        whileInView={{
                            opacity: 1,
                        }}
                        initial={{
                            opacity: 0,
                        }}
                        transition={{
                            duration: 0.5,
                        }}
                        viewport={{
                            once: true,
                            amount: 0.1,
                        }}
                    >
                        <AspectRatio pos="relative" ratio={1}>
                            {data.type === "image" ? (
                                <Foto data={data} />
                            ) :
                                <Film data={data} />}
                            {/* data.type === "video" ? (
                            ) : (
                                <Youtube data={data} />
                            )} */}
                        </AspectRatio>
                    </motion.div>
                ))}
            </SimpleGrid>
        </TabPanel>
    );
}

export default function Gallery() {
    const [load, setLoad] = useState(true);
    const [data, setDatas] = useState({
        studio: [],
        gymManagement: [],
        certification: [],
    });
    useEffect(() => {
        getGallery().then((res) => {
            setDatas(res);
            setLoad(false);
        });
    }, []);

    if (load) {
        return <Loading />
    }

    return (
        <Tabs
            mx={{ base: 0, xl: 8 }}
            pt={{ base: "10vh", xl: "20vh" }}
            isFitted
        >
            <TabList mb="1em" flexDir={["column", "row"]}>
                {["I-FIT STUDIO", "GYM MANAGEMENT", "CERTIFICATIONS"].map(
                    (text) => (
                        <Tab
                            key={text}
                            borderColor="white"
                            _selected={{ color: "pink", borderColor: "pink" }}
                        >
                            <Heading>{text}</Heading>
                        </Tab>
                    )
                )}
            </TabList>
            <TabPanels>
                <LocalTabPanel datas={data.studio} />
                <LocalTabPanel datas={data.gymManagement} />
                <LocalTabPanel datas={data.certification} />
            </TabPanels>
        </Tabs>
    );
}
