import { Box, Text, VStack } from "@chakra-ui/react";
import { motion } from "framer-motion";

import IFSubMenu from "../components/IF_SubMenu";
import IFBanner from "../components/IF_Template/IF_banner";
import IFPricing from "../components/IF_Template/IF_Pricing";
import IFSwiper from "../components/IF_Swiper";
import IFHeading from "../components/IF_Heading";

import FadeIn from "../components/Animation/FadeIn";

import { getTrainer } from "../api/trainer";
import { useEffect, useState } from "react";
import Loading from "../components/Layout/Loading";

export default function Trainer() {
    const [load, setLoad] = useState(true);
    const [data, setDatas] = useState([]);
    useEffect(() => {
        getTrainer().then((res) => {
            setDatas(res);
            setLoad(false);
        });
    }, []);

    if (load) {
        return <Loading />
    }
    return (
        <>
            <IFBanner
                src={process.env.REACT_APP_IMAGE_URL + data.datas[0].value}
                h={{ base: "auto", md: "65vh", lg: "70vh", xl: "100vh" }}
            >
                <motion.div
                    animate={{
                        opacity: [0, 0.5, 1],
                        x: ["100%", "-20%", "0%"],
                    }}
                    transition={{ duration: 0.5, delay: 1 }}
                >
                    <IFSubMenu text1={data.datas[1].value} text2={data.datas[2].value} />
                    <Text whiteSpace="pre-line" fontSize="xl">
                        {data.datas[3].value}
                    </Text>
                </motion.div>
            </IFBanner>
            <Box mt={8} />
            <Box>
                <IFHeading>PERSONAL TRAINING PROGRAM</IFHeading>
                <IFPricing
                    gradientColor="pink"
                    datas={JSON.parse(data.datas[5].value)}
                >
                    <VStack gap={2} textAlign="center">
                        <FadeIn>
                            <Text whiteSpace="pre-line" fontSize="xl">
                                {data.datas[4].value}
                            </Text>
                        </FadeIn>
                    </VStack>
                </IFPricing>
            </Box>

            <IFSwiper data={data.album} />
        </>
    );
}
